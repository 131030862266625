<template>
  <div>
    <v-slide-y-transition mode="out-in">
      <CarForm
        v-if="branch?.codigo == 1"
        ref="formComponent"
        v-bind="componentData" />
      <p v-else>
        Haga clic en el boton de <b>Tarifas</b> para continuar con la
        simulación.
      </p>
    </v-slide-y-transition>

    <v-layout justify-end>
      <v-flex class="mr-3">
        <v-slide-x-transition>
          <v-alert
            max-height="60"
            :value="error.show"
            :color="error.type"
            dense
            :type="error.type"
            outlined
            dark
            dismissible
            @input="inputAlert">
            {{ error.text }}
          </v-alert>
        </v-slide-x-transition>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CarForm from "@/components/User-Components/Forms/CarForm.vue";
export default {
  name: "NewQuotForms",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    branch: {
      type: Object,
      default: () => null,
    },
    dataClient: {
      type: Object,
      default: () => {},
    },
    assistedSelling: {
      type: Boolean,
      default: false,
    },
    dealerStructure: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Object,
      default: () => ({
        type: "error",
        show: false,
        text: "",
      }),
    },
  },
  components: {
    CarForm,
  },
  computed: {
    componentData() {
      return {
        branch: this.branch,
        dataCategory: this.parseDataCategory,
        dataClient: this.parseDataClient,
        assistedSelling: this.assistedSelling,
        dealerStructure: this.dealerStructure,
      };
    },
    parseDataCategory() {
      return {
        categoria_descripcion: this.branch.ramo_descripcion,
        categoria_codigo: this.branch.codigo,
        categoria: this.branch._id,
      };
    },
    parseDataClient() {
      const ClientType = this.dataClient?.tipo_persona ?? "";
      const ClientDataKey = ClientType === "JUR" ? "contacto" : "cliente";
      return {
        tipo_documento: this.dataClient?.cliente?.tipo_documento ?? "RUT",
        numero_documento: this.dataClient?.cliente?.numero_documento ?? "",
        cliente: `${this.dataClient?.[ClientDataKey]?.nombre ?? ""} ${
          this.dataClient?.[ClientDataKey]?.primer_apellido ?? ""
        } ${this.dataClient?.[ClientDataKey]?.segundo_apellido ?? ""}`,
        primer_apellido:
          this.dataClient?.[ClientDataKey]?.primer_apellido ?? "",
        segundo_apellido:
          this.dataClient?.[ClientDataKey]?.segundo_apellido ?? "",
        nombres: this.dataClient?.[ClientDataKey]?.nombre ?? "",
        fecha_nacimiento:
          this.dataClient?.[ClientDataKey]?.fecha_nacimiento ?? "",
        genero: this.dataClient?.[ClientDataKey]?.genero ?? "",
        tipo_persona: ClientType,
        comuna: this.dataClient?.cliente.comuna ?? "",
        comuna_descripcion: this.dataClient?.cliente.comuna_descripcion ?? "",
        region: this.dataClient?.cliente.region ?? "",
        provincia: this.dataClient?.cliente.provincia ?? "",
      };
    },
  },
  methods: {
    async submit() {
      const formComponent = this.$refs.formComponent;
      const form = formComponent?.$refs?.form;
      let formData = {};
      // Verificar si existe un formulario para el ramo
      if (form) {
        // Validar formulario
        if (form?.validate()) {
          await formComponent.submit().then((response) => {
            formData = response;
          });
          this.$emit("submit", formData);
        }
      } else {
        // De lo contrario establecer data por defecto
        formData = {
          ...this.parseDataCategory,
          ...this.parseDataClient,
        };
        this.$emit("submit", formData);
      }
    },
    inputAlert(e) {
      if (!e) {
        this.$emit("close-alert");
      }
    },
  },
};
</script>
