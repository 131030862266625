<template>
  <v-layout>
    <v-chip-group column v-model="ramo">
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :disabled="item.eliminado"
        @click="selectRamo(item)"
        name="detalle_cliente"
        rounded
        color="primary"
        class="text-none px-6 py-6"
        style="width: 144px; height: 40px"
      >
        <v-icon left> mdi-car-outline </v-icon>
        {{ $t(getDescription(item.ramo_descripcion)) }}
      </v-btn>
    </v-chip-group>
  </v-layout>
</template>

<script>
export default {
  name: "RamoCardList",
  data: () => ({
    ramo: "",
  }),
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    country() {
      return this.$store.getters.getCountry;
    },
  },
  methods: {
    selectRamo(item) {
      this.$emit("input", item);
    },
    getDescription(description) {
      if (description === "AUTOMOTRIZ") {
        return "automotriz";
      }
      return description;
    },
  },
};
</script>
