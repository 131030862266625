<template>
  <div>
    <ScrollSidebar>
      <template v-slot:content>
        <v-row no-gutters>
          <v-col xs="12" class="d-flex justify-space-between mb-6">
            <v-btn color="primary" class="text-none" text @click="goBack()">
              <Icon name="arrow_back" size="20px" class="mr-2" />
              Volver a detalle del cliente</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="10" class="mb-6">
            <!-- <v-fade-transition mode="out-in"> -->
            <v-card flat outlined>
              <v-card-text class="px-15 py-10">
                <h4 class="mb-6">Nueva cotizacion</h4>
                <RamoCard
                  v-if="getCategories.length > 0 && !loading.ramos"
                  @input="handlerSelectCategory"
                  :items="getCategories" />

                <div v-else-if="loading.ramos" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="80"></v-progress-circular>
                </div>
                <p v-else class="grey--text text--darken-1 text-center">
                  <v-icon>mdi-information</v-icon> No se han encontrado ramos.
                </p>
                <v-layout class="my-6" v-if="ramoSelected">
                  <quot-forms
                    ref="CotForm"
                    :error="error"
                    :branch="ramoSelected"
                    :data-client="getUserInfo"
                    :assistedSelling="userChannelInfo?.venta_asistida"
                    :dealerStructure="dealerStructure"
                    :loading="loading.form"
                    @submit="handleCotizar"
                    @close-alert="error.show = false" />
                </v-layout>
              </v-card-text>
              <!-- <div class="card-content px-15 py-10"></div> -->
            </v-card>
            <v-col cols="12" v-if="ramoSelected">
              <div class="d-flex justify-center">
                <v-btn
                  @click="submit"
                  color="primary"
                  width="204"
                  height="40"
                  rounded
                  class="text-none"
                  name="boton_cotizar"
                  :loading="$refs.CotForm?.loading"
                  >Cotizar</v-btn
                >
              </div>
            </v-col>
            <!-- </v-fade-transition> -->
          </v-col>
        </v-row>

        <new-quotation-modal ref="newQuotModal" />
        <confirm-modal ref="confirm" />
      </template>

      <template v-slot:sidebar>
        <Pendings
          :show-docs="showPendingDocs"
          :show-quotes="showPendingQuotes"
          :quotations="Cotizaciones"
          :documents="Documentos"
          :user="getUserInfo"
          :loading-docs="loading.documents"
          :loading-quotes="loading.quotes" />
      </template>
    </ScrollSidebar>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import RamoCard from "@/components/Cards/Cotizacion/Ramo-card.vue";
import QuotForms from "@/components/User-Components/Forms/QuotForm.vue";
import ScrollSidebar from "@/layouts/ScrollSidebar.vue";
import Pendings from "@/components/User-Components/Pending/Pendings.vue";
import Icon from "@/components/Icon/Icon.vue";
import NewQuotationModal from "@/components/Modal/ClientDetail/ModalNuevoQuot.vue";
import QuotResume from "@/Mixins/resumeQuote.js";

import ConfirmModal from "@/components/Modal/ConfirmModal.vue";

export default {
  name: "NuevaCotizacion",
  mixins: [QuotResume],
  props: {
    rut: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      ramoSelected: null,
      loading: {
        ramos: false,
        concesionarios: false,
        form: false,
        documents: false,
        quotes: false,
      },
      error: {
        show: false,
        text: "",
        type: "error",
      },
    };
  },
  components: {
    RamoCard,
    QuotForms,
    ScrollSidebar,
    Pendings,
    Icon,
    NewQuotationModal,
    ConfirmModal,
  },
  created() {
    this.loadData();
  },
  watch: {
    getCategories(newVal) {
      if (newVal?.length === 1) {
        this.selectCategory(newVal[0]);
      }
    },
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo", "getCategories"]),
    ...mapMutations("Users", ["setClearState"]),
    ...mapGetters("Quot", ["Cotizaciones"]),
    ...mapGetters("Documents", ["Documentos"]),

    userChannelInfo() {
      return this.$store.getters.getGeneralInfo;
    },
    dealerStructure() {
      return this.$store.getters.DealerStructure;
    },

    showPendingQuotes() {
      const filter =
        this.userChannelInfo?.personalizacion_corporativa?.acceso_pantallas;
      return (
        filter?.find(
          (val) =>
            val.codigo === "COTIZACIONES_PENDIENTES" &&
            val.seccion === "DETALLE_CLIENTE"
        )?.activo ?? true
      );
    },
    showPendingDocs() {
      const filter =
        this.userChannelInfo?.personalizacion_corporativa?.acceso_pantallas;
      return (
        filter?.find(
          (val) =>
            val.codigo === "DOCUMENTOS_PENDIENTES" &&
            val.seccion === "DETALLE_CLIENTE"
        )?.activo ?? true
      );
    },
  },
  methods: {
    ...mapActions("Users", ["findCategories", "setClient"]),
    ...mapActions("Quot", ["validarCotizacion", "crearCotizacion"]),
    ...mapActions("Documents", ["GetDocs"]),
    ...mapActions("Quot", ["GetCotizaciones"]),

    formHeight(form) {
      let height = 250;
      switch (form) {
        case "1":
          height = 910;
          break;

        default:
          height = 250;
          break;
      }

      return height;
    },

    loadData() {
      return new Promise((resolve) => {
        this.setClient(this.rut);
        this.setDocuments();
        this.setQuotes();
        this.handlerFindCategories();

        if (this.userChannelInfo?.venta_asistida) {
          this.handlerFindDealerStructure();
        } else {
          this.$store.commit("setDealerStructure", []);
        }
        resolve("ok");
      });
    },

    // ===  Establecer las cotizaciones del cliente ===
    setQuotes() {
      this.loading.quotes = true;
      return new Promise((resolve) => {
        this.GetCotizaciones({ limite: 5, rut: this.rut })
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading.quotes = false));
        resolve("ok");
      });
    },

    // === Establecer los documentos pendientes del cliente ===
    setDocuments() {
      this.loading.documents = true;
      return new Promise((resolve) => {
        this.GetDocs({
          cliente: this.rut,
          limit: 5,
          estados_documentos: "PEN,REC",
        })
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading.documents = false));
        resolve("ok");
      });
    },

    // Proceso de cotizacion
    Cotizar(payload) {
      const newModal = this.$refs.newQuotModal;
      // Validar si la cotizacion ya existe
      this.validarCotizacion(payload)
        .then((response) => {
          // Si la cotizacion existe
          if (response) {
            // Mostrar modal de confirmar (Remotar o crear nueva)
            newModal
              .show({
                dataClient: this.getUserInfo,
                quotData: response,
              })
              .then(async (r) => {
                if (r) {
                  // Crear nueva
                  await this.EnviarCotizacion(payload.data);
                } else {
                  // Retomar existente
                  await this.Retomar(response);
                }
              })
              .finally(() => {
                newModal.stopLoad();
                newModal.close();
                this.loading.form = false;
              });
          } else {
            this.EnviarCotizacion(payload.data);
          }
        })
        .catch((info) => {
          const result = info.response;
          const status = result.status;
          this.error.type = "error";
          this.error.text = "Ha ocurrido un error al intentar simular";
          if (status >= 400 && status < 500) {
            this.error.type = "info";
            this.error.text = (result?.data?.data?.lista_mensaje ?? [])
              .map((a) => a.mensaje)
              .join(", ");
            this.EnviarCotizacion(payload.data);
          } else {
            this.loading.form = false;
          }
          if (this.error.text) {
            this.error.show = true;
          }
        });
    },
    // Verificar el ramo antes de cotizar (Condicionales por ramo)
    handleCotizar(formData) {
      this.loading.form = true;
      const code = this.ramoSelected.codigo.toString();
      switch (code) {
        // Cotizar de forma normal (Ciclo 1 automotriz)
        case "1":
          {
            this.Cotizar(formData);
          }
          break;
        // Por defecto cotizar directamente, solo con el ramo.
        default:
          {
            const confirmModal = this.$refs.confirm;
            confirmModal
              .show({
                title: "Confirmar simulación",
                description: `¿Desea continuar con la simulación para el ramo ${formData.categoria_descripcion}?`,
              })
              .then((r) => {
                if (r) {
                  this.EnviarCotizacion(formData);
                }
              });
          }
          break;
      }
    },
    // Crear una nueva cotizacion
    async EnviarCotizacion(data) {
      await this.crearCotizacion(data)
        .then((response) => {
          // Cotizacion creada con exito, enviar al tablon de ofertas
          if (response?.cotizacion) {
            this.$router.push({
              name: "quotationOffers",
              params: {
                id: response.cotizacion.numero_cotizacion,
              },
            });
          } else {
            // Mostrar error si no se consiguen ofertas
            this.error.type = "warning";
            this.error.text =
              "No se han encontrado resultados para esta simulación";
            this.error.show = true;
            this.loading.form = false;
          }
        })
        .catch((error) => {
          this.error.text =
            error?.defaultError ?? "Ha ocurrido un error al intentar simular.";
          this.error.type = "error";
          if (error?.data?.lista_mensaje?.length > 0) {
            this.error.text +=
              ": " + error.data.lista_mensaje.map((a) => a.mensaje).join(", ");
          }
          this.error.show = true;
          this.loading.form = false;
        });
    },
    // Verificar ramo seleccionado (Condicionales por ramo)
    handlerSelectCategory(category) {
      this.error.show = false;
      const code = category.codigo.toString();
      this.ramoSelected = category;

      switch (code) {
        case "1":
          {
            this.selectCategory(category);
          }
          break;

        default:
          break;
      }
    },
    selectCategory(category) {
      const getMateriaAsegurada = function (ramo) {
        let materias = [];
        if (!ramo.segmentos) {
          return materias;
        }
        for (var seg in ramo.segmentos) {
          if (
            !ramo.segmentos[seg].tipos_productos ||
            ramo.segmentos[seg].eliminado
          ) {
            continue;
          }
          for (var tp in ramo.segmentos[seg].tipos_productos) {
            if (
              !ramo.segmentos[seg].tipos_productos[tp].materias ||
              ramo.segmentos[seg].tipos_productos[tp].eliminado
            ) {
              continue;
            }
            for (var ma in ramo.segmentos[seg].tipos_productos[tp].materias) {
              if (
                ramo.segmentos[seg].tipos_productos[tp].materias[ma]
                  .eliminado ||
                materias.find(
                  (e) =>
                    e.label.toUpperCase() ==
                    ramo.segmentos[seg].tipos_productos[tp].materias[
                      ma
                    ].materia_descripcion
                      .toUpperCase()
                      .replace(/ /g, "")
                )
              ) {
                continue;
              }
              materias.push({
                // label: `${ramo.segmentos[seg].tipos_productos[tp].tipo_producto_descripcion} | ${ramo.segmentos[seg].tipos_productos[tp].materias[ma].materia_descripcion}`,
                label: ramo.segmentos[seg].tipos_productos[tp].materias[
                  ma
                ].materia_descripcion.replace(/ /g, ""),
                idMateria:
                  ramo.segmentos[seg].tipos_productos[tp].materias[ma].materia,
                codigoMateria:
                  ramo.segmentos[seg].tipos_productos[tp].materias[ma].codigo,
                idTipoProducto: ramo.segmentos[seg].tipos_productos[tp]._id,
                idSegmento: ramo.segmentos[seg]._id,
                descripcionMateria:
                  ramo.segmentos[seg].tipos_productos[tp].materias[ma]
                    .materia_descripcion,
                descripcionTipoProducto:
                  ramo.segmentos[seg].tipos_productos[tp]
                    .tipo_producto_descripcion,
                descripcionSegmento: ramo.segmentos[seg].segmento_descripcion,
              });
            }
          }
        }
        return materias;
      };
      if (this.ramoSelected) {
        this.ramoSelected.materias = getMateriaAsegurada(category);
      }
    },
    handlerFindCategories() {
      this.loading.ramos = true;
      this.findCategories().finally(() => {
        this.loading.ramos = false;
      });
    },
    handlerFindDealerStructure() {
      this.loading.concesionarios = true;
      this.$store.dispatch("REQUEST_DEALER_STRUCTURE").finally(() => {
        this.loading.concesionarios = false;
      });
    },

    goBack() {
      if (this.rut) {
        this.$router.push({
          name: "UserDetail",
          params: {
            rut: this.rut,
          },
        });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },

    submit() {
      const formComponentRef = this.$refs.CotForm;
      formComponentRef.submit();
    },
  },
};
</script>
