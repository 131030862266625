<template>
  <v-form ref="form">
    <div class="text-center">
      <v-dialog width="472px" v-model="openGpsModal" attach="#main-container">
        <v-card outlined>
          <v-card-title class="mt-1">
            <b> GPS del vehículo </b>
            <v-spacer></v-spacer>
            <v-icon class="global-pointer mr-3" @click="openGpsModal = false"
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            <Alert variant="warning" class="w-100 mt-2">
              <template slot="content">
                <div>
                  <span class="p"
                    >Recuerda que para <b>asegurar este vehículo</b> debe contar
                    con <b>GPS</b>
                  </span>
                </div>
              </template>
            </Alert>
            <div class="d-flex justify-center">
              <v-btn
                class="text-none px-9"
                rounded
                color="primary"
                @click="openGpsModal = false">
                Entendido
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-row>
      <v-col cols="12" class="py-4 my-0">
        <p>¿El vehículo a cotizar es nuevo o usado?*</p>
        <div class="d-flex justify-space-between py-4">
          <v-btn
            v-if="allowNewCar"
            x-large
            text
            color="primary"
            @click="selectedCarState = 'new'"
            :class="selectedCarState === 'new' ? 'selected' : ''"
            class="text-none py-7 px-16 br-16"
            >Vehículo nuevo</v-btn
          >
          <v-btn
            v-if="allowUsedCar"
            x-large
            text
            color="primary"
            @click="selectedCarState = 'used'"
            class="text-none py-7 px-16 br-16"
            :class="selectedCarState === 'used' ? 'selected' : ''"
            >Vehículo usado</v-btn
          >
        </div>
      </v-col>

      <v-col
        :order="
          country === countries.CL
            ? 1
            : country === countries.PE && selectedCarState === 'new'
            ? 5
            : 1
        "
        cols="12"
        class="py-0 my-0"
        v-if="
          country === countries.PE ||
          (country === countries.CL && selectedCarState === 'new')
        ">
        <p>{{ $t("patenteoFactura") }}</p>
        <v-radio-group v-model="documentType">
          <div class="d-flex align-center">
            <v-col cols="6">
              <v-radio
                :label="mapPatente[country].patente.label"
                :value="mapPatente[country].patente.value" />
            </v-col>
            <v-col cols="6">
              <v-radio
                :label="mapPatente[country].factura.label"
                :value="mapPatente[country].factura.value" />
            </v-col>
          </div>
        </v-radio-group>
      </v-col>
      <v-col
        :cols="country === countries.PE ? 6 : 12"
        class="py-0 my-0"
        :order="
          country === countries.CL ? '3' : selectedCarState === 'new' ? 5 : 2
        "
        v-if="documentType === 'patente'">
        <!-- CAMPO PATENTE -->
        <LabelComponent :text="patente.label" />
        <v-text-field
          v-model="patente.value"
          :rules="patente.rules"
          :placeholder="patente.placeholder"
          outlined
          dense
          :hint="patente.hint"
          persistent-hint
          v-mask="patentMask"
          @keyup="patente.value = patente.value.toUpperCase()"
          name="patente" />
      </v-col>
      <v-col
        v-if="country === countries.PE && documentType === 'patente'"
        cols="6"
        :order="selectedCarState === 'new' ? 6 : 2"></v-col>

      <!-- <template
        v-if="
          country === countries.PE &&
          (documentType === 'patente' || selectedCarState === 'used')
        ">
        <v-col
          :order="selectedCarState === 'new' ? 5 : 2"
          cols="6"
          class="py-0 my-0">
          <LabelComponent text="Placa" />
          <v-text-field
            v-model="patente.value"
            :rules="patente.rules"
            placeholder="Escriba la placa"
            outlined
            dense
            persistent-hint
            v-mask="patentMask"
            @keyup="patente.value = patente.value.toUpperCase()"
            name="patente"
          />
        </v-col>
        <v-col cols="6" :order="selectedCarState === 'new' ? 6 : 2"></v-col>
      </template> -->
      <v-col
        :order="
          country === countries.CL ? 5 : selectedCarState === 'new' ? 1 : 4
        "
        cols="12"
        md="6"
        class="py-0 my-0">
        <LabelComponent :text="select_product_type.label" />
        <v-autocomplete
          class="white"
          :items="branch?.materias ?? []"
          v-model="select_product_type.selected"
          item-text="label"
          return-object
          :placeholder="select_product_type.placeholder"
          :rules="select_product_type.rules"
          @change="SelectProduct"
          mandatory
          outlined
          dense
          append-icon="mdi-chevron-down"
          name="materia_aseg" />
        <v-divider class="mb-3"></v-divider>
      </v-col>
      <v-col
        :order="
          country === countries.CL ? 6 : selectedCarState === 'new' ? 2 : 5
        "
        cols="12"
        md="6"
        class="py-0 my-0">
        <!-- CAMPO MARCA -->
        <LabelComponent :text="select_brand.label" />
        <v-autocomplete
          outlined
          :items="getBrands"
          :disabled="!select_product_type.selected"
          v-model="select_brand.selected"
          return-object
          :placeholder="select_brand.placeholder"
          :rules="select_brand.rules"
          :loading="loadingBrand"
          @change="change"
          item-text="marca_descripcion"
          class="white"
          dense
          append-icon="mdi-chevron-down"
          name="marca" />
      </v-col>
      <v-col
        :order="
          country === countries.CL ? 7 : selectedCarState === 'new' ? 3 : 6
        "
        cols="12"
        md="6"
        class="py-0 my-0">
        <!-- CAMPO MODELO -->
        <LabelComponent :text="select_model.label" />
        <v-autocomplete
          outlined
          :disabled="!select_product_type.selected || !select_brand.selected"
          :items="getModels"
          v-model="select_model.selected"
          return-object
          :placeholder="select_model.placeholder"
          :rules="select_model.rules"
          :loading="loadingModels"
          item-text="modelo_descripcion"
          class="white"
          dense
          append-icon="mdi-chevron-down"
          name="modelo" />
      </v-col>
      <v-col
        :order="
          country === countries.CL ? 8 : selectedCarState === 'new' ? 4 : 7
        "
        cols="12"
        md="6"
        class="py-0 my-0">
        <!-- CAMPO ANIO -->
        <LabelComponent :text="select_year.label" />
        <v-select
          class="white mb-3"
          :items="years"
          v-model="select_year.selected"
          item-text="label"
          item-value="value"
          :placeholder="select_year.placeholder"
          :loading="yearsLoading"
          :rules="select_year.rules"
          mandatory
          outlined
          dense
          width="100%"
          append-icon="mdi-chevron-down"
          name="anio" />
      </v-col>

      <v-col order="10" cols="12" md="6" class="py-0 my-0">
        <!-- CAMPO DE USO DE PRODUCTO -->
        <LabelComponent :text="select_product_use.label" />
        <v-select
          class="white mb-3"
          :items="branch?.uso_producto ?? []"
          v-model="select_product_use.selected"
          item-text="uso_producto_descripcion"
          return-object
          item-disabled="eliminado"
          :placeholder="select_product_use.placeholder"
          :rules="select_product_use.rules"
          mandatory
          outlined
          dense
          append-icon="mdi-chevron-down"
          name="uso_producto" />
      </v-col>
      <template v-if="country === countries.PE">
        <v-col order="10" cols="12" md="6" class="py-0 my-0">
          <LabelComponent text="Departamento" />
          <v-select
            class="white mb-3"
            :items="regiones"
            v-model="region"
            item-text="nombre"
            item-value="codigo"
            return-object
            item-disabled="eliminado"
            placeholder="Seleccione Departamento"
            :rules="select_product_use.rules"
            mandatory
            outlined
            disabled
            dense
            append-icon="mdi-chevron-down"
            name="departamento" />
        </v-col>
        <v-col order="11" cols="12" md="6" class="py-0 my-0">
          <LabelComponent text="Provincia" />
          <v-select
            class="white mb-3"
            :items="provincias"
            v-model="provincia"
            item-text="nombre"
            item-value="codigo"
            disabled
            return-object
            item-disabled="eliminado"
            placeholder="Seleccione Provincia"
            :rules="select_product_use.rules"
            mandatory
            outlined
            dense
            append-icon="mdi-chevron-down"
            name="provincia" />
        </v-col>
        <v-col order="12" cols="12" md="6" class="py-0 my-0">
          <LabelComponent text="Distrito" />
          <v-select
            class="white mb-3"
            :items="comunas"
            v-model="comuna"
            item-text="nombre"
            disabled
            item-value="codigo"
            return-object
            item-disabled="eliminado"
            placeholder="Seleccione Distrito"
            :rules="select_product_use.rules"
            mandatory
            outlined
            dense
            append-icon="mdi-chevron-down"
            name="distrito" />
        </v-col>
        <v-col order="12" cols="12" md="6" class="py-0 my-0">
          <LabelComponent text="Suma asegurada*" />
          <v-text-field
            v-model="suma_asegurada"
            outlined
            dense
            :rules="valor_asegurado.rules"
            name="suma_asegurada"
            class="customInput">
            <template v-slot:prepend-inner>
              <div class="inputInner">US$</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col order="12" cols="12" md="6" class="py-0 my-0 gps">
          <p>¿El vehículo cuenta con GPS?</p>
          <v-radio-group
            v-model="havegps"
            :rules="[rules.gpsRules(havegps, suma_asegurada)]">
            <div class="d-flex align-center">
              <v-col cols="6" class="py-0 px-0 my-0">
                <v-radio label="Si" value="SI" />
              </v-col>
              <v-col cols="6" class="py-0 px-0 my-0">
                <v-radio label="No" value="NO" />
              </v-col>
            </div>
          </v-radio-group>
        </v-col>
      </template>
      <!-- <v-col cols="12" md="6" class="py-0 my-0"> -->
      <!-- CAMPO NUEVO VEHICULO (Cero Km) -->
      <!-- <LabelComponent :text="select_new_vehicle.label" /> -->
      <!-- <v-fade-transition>
          <v-progress-linear
            class="mt-1"
            indeterminate
            v-if="carUseLoading"
          ></v-progress-linear>
        </v-fade-transition> -->

      <!-- <v-select
          :loading="carUseLoading"
          :items="select_new_vehicle.options"
          v-model="select_new_vehicle.value"
          outlined
          dense></v-select> -->

      <!-- <v-radio-group
          color="primary"
          v-model="select_new_vehicle.value"
          class="mt-1"
          row
          :hint="getHintVehicleUse"
          persistent-hint
        >
          <v-radio
            label="Si"
            :disabled="select_new_vehicle.disabled_true"
            :value="1"
          ></v-radio>
          <v-radio
            :disabled="select_new_vehicle.disabled_false"
            label="No"
            :value="2"
          ></v-radio>
        </v-radio-group> -->
      <!-- </v-col> -->

      <v-col v-if="formType == 3" cols="12" class="py-0 my-0">
        <LabelComponent text="Precio venta" />
        <InputField
          size="long"
          color="#0077c8"
          detail_color="rgba(242, 242, 242, 0.7)"
          :model="precio_venta"
          placeholder="Precio venta"
          readonly="false"
          @input="precio_venta = $event"
          height="44"
          :hide="false"
          :price="true"
          v-mask="pricemask"
          :rules="[rules.required]"
          name="precio_venta" />
      </v-col>
      <v-col v-if="formType == 3" cols="12" class="py-0 my-0">
        <LabelComponent text="Peso carga" />
        <InputField
          size="long"
          color="#0077c8"
          detail_color="rgba(242, 242, 242, 0.7)"
          :model="peso_carga"
          placeholder="Peso carga"
          readonly="false"
          @input="peso_carga = $event"
          height="44"
          :rules="[rules.required]"
          :hide="false"
          :weight="true"
          v-mask="weightmask"
          name="peso_carga" />
      </v-col>
    </v-row>

    <!-- CAMPOS DE TIPO CAMION -->
    <v-fade-transition>
      <v-row
        v-if="
          select_product_type.selected?.codigoMateria === 'camion' ||
          select_product_type.selected?.codigoMateria === 'remolque'
        ">
        <!-- VALOR ASEGURADO -->
        <v-col cols="12" class="py-0">
          <v-divider class="mb-6"></v-divider>
          <LabelComponent :text="valor_asegurado.label" />
          <v-text-field
            :placeholder="valor_asegurado.placeholder"
            v-model="valor_asegurado.value"
            outlined
            dense
            :hint="valor_asegurado.hint"
            persistent-hint
            :rules="valor_asegurado.rules"></v-text-field>
        </v-col>
        <!-- PLAZO DE CREDITO -->
        <v-col cols="12" md="6">
          <LabelComponent :text="select_plazo.label" />
          <v-select
            :placeholder="select_plazo.placeholder"
            :items="select_plazo.answers"
            v-model="select_plazo.selected"
            outlined
            dense
            :rules="select_plazo.rules"></v-select>
        </v-col>

        <v-col cols="12" md="6">
          <LabelComponent :text="acoplado.label" />
          <v-checkbox
            dense
            v-model="acoplado.selected"
            :true-value="true"
            :false-value="false"
            :hint="acoplado.hint"
            persistent-hint></v-checkbox>
        </v-col>
      </v-row>
    </v-fade-transition>

    <!-- CAMPOS DE VENTA ASISTIDA -->
    <v-fade-transition>
      <div v-if="assistedSelling" class="my-6">
        <h4 class="mb-3">Venta de Terceros</h4>
        <v-fade-transition>
          <v-row v-if="dealerStructure.length > 0">
            <!-- CAMPO DE CONSECIONARIOS -->
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="dealerStructure"
                v-model="select_dealer.selected"
                return-object
                :label="select_dealer.label"
                :placeholder="select_dealer.placeholder"
                outlined
                dense
                :rules="select_dealer.rules"
                item-text="nombre"
                @change="
                  (select_branchs.selected = null),
                    (select_sellers.selected = null)
                "
                class="white"
                append-icon="mdi-chevron-down"
                name="concesionario"></v-autocomplete>
            </v-col>
            <!-- CAMPO DE SUCURSALES -->
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="select_dealer.selected?.sucursales ?? []"
                v-model="select_branchs.selected"
                return-object
                :label="select_branchs.label"
                :placeholder="select_branchs.placeholder"
                :rules="select_branchs.rules"
                @change="select_sellers.selected = null"
                outlined
                dense
                item-text="nombre"
                class="white"
                append-icon="mdi-chevron-down"
                name="sucursal"></v-autocomplete>
            </v-col>
            <!-- CAMPO DE VENDEDORES -->
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="select_branchs.selected?.vendedores ?? []"
                v-model="select_sellers.selected"
                return-object
                :label="select_sellers.label"
                :placeholder="select_sellers.placeholder"
                :rules="select_sellers.rules"
                outlined
                dense
                :item-text="
                  (item) =>
                    `${item.nombre}  ${item.primer_apellido}  ${item.segundo_apellido}`
                "
                class="white"
                append-icon="mdi-chevron-down"
                name="vendedores">
              </v-autocomplete>
            </v-col>
          </v-row>
          <div v-else>
            <v-alert border="left" colored-border type="warning" elevation="2">
              No se han encontrado concesionarios registrados
            </v-alert>
          </div>
        </v-fade-transition>
      </div>
    </v-fade-transition>
  </v-form>
</template>
<script>
import InputField from "@/components/Inputs/Input.vue";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { mapActions, mapGetters } from "vuex";
import LabelComponent from "@/components/Inputs/Label.vue";
// import Icon from "@/components/Icon/Icon.vue";

import { patentMask } from "@/helpers/mask.js";
import { validarFactura, validarNumerico } from "@/store/resources/validate.js";
import { COUNTRIES } from "../../../helpers/constants";
import Alert from "@/components/Alert/Alert.vue";

const currencyMask = createNumberMask({
  prefix: "",

  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: ".",
  integerLimit: 3000000000,
});
const weightMask = createNumberMask({
  prefix: "",
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: ".",
  integerLimit: 3000000000,
});

export default {
  name: "NewQuotCarForm",
  components: {
    InputField,
    LabelComponent,
    Alert,
  },
  props: {
    branch: {
      type: Object,
      default: () => null,
    },
    dataClient: {
      type: Object,
      default: () => {},
    },
    dataCategory: {
      type: Object,
      default: () => {},
    },
    assistedSelling: {
      type: Boolean,
      default: false,
    },
    dealerStructure: {
      type: Array,
      default: () => [],
    },
    rut: {
      type: String,
      default: "",
    },
  },
  created() {
    this.fillYears();
    this.fillCarUse();
  },
  data: () => ({
    openGpsModal: false,
    countries: COUNTRIES,
    allowNewCar: false,
    allowUsedCar: false,
    suma_asegurada: null,
    havegps: "SI",
    selectedCarState: "new",
    documentType: "patente",
    yearsLoading: false,
    carUseLoading: false,
    pricemask: currencyMask,
    weightmask: weightMask,
    loadingBrand: false,
    loadingModels: false,
    formType: 0,
    formData: null,
    region: null,
    provincia: null,
    comuna: null,
    mapPatente: {
      CL: {
        patente: { label: "Patente", value: "patente" },
        factura: { label: "Factura", value: "factura" },
      },
      PE: {
        patente: { label: "Placa", value: "patente" },
        factura: { label: "En trámite", value: "tramite" },
      },
    },
    rules: {
      required: (value) => !!value || "Campo requerido.",
      peso: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Peso inválido";
      },
      precio: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Precio inválido";
      },
      gpsRules: (value, suma) => {
        if (value !== "SI" && suma > 50000) {
          return "Debe contar con GPS";
        }
        return true;
      },
    },
    select_product_type: {
      label: "Materia asegurada*",
      placeholder: "Seleccione una materia asegurada",
      selected: "",
      rules: [(value) => !!value || "Debe seleccionar una materia asegurada."],
    },

    // select_new_vehicle: {
    //   label: "¿Cero KM?",
    //   value: 1,
    //   disabled_true: false,
    //   disabled_false: false,
    //   options: [],
    //   rules: [
    //     (value) =>
    //       !!value || "Porfavor especifique si el vehículo es nuevo o usado.",
    //   ],
    // },

    select_year: {
      label: "Año*",
      selected: "",
      placeholder: "Año",
      rules: [(value) => !!value || "Seleccione un año."],
    },
    select_brand: {
      label: "Marca*",
      selected: null,
      placeholder: "Buscar Marca",
      rules: [(value) => !!value || "Debe seleccionar la marca."],
    },
    select_model: {
      label: "Modelo*",
      selected: null,
      placeholder: "Buscar Modelo",
      rules: [(value) => !!value || "Debe seleccionar el modelo."],
    },

    select_product_use: {
      label: "Uso del producto*",
      selected: "",
      placeholder: "Seleccione un uso del producto",
      rules: [
        (value) => !!value || "Debe seleccionar un uso para el Producto.",
      ],
    },
    select_dealer: {
      label: "Concesionario*",
      selected: null,
      placeholder: "Seleccione un concesionario",
      rules: [(value) => !!value || "Debe seleccionar un concesionario."],
    },
    select_branchs: {
      label: "Sucursal*",
      selected: null,
      placeholder: "Seleccione una sucursal",
      rules: [(value) => !!value || "Debe seleccionar una sucursal."],
    },
    select_sellers: {
      label: "Vendedor*",
      selected: null,
      placeholder: "Seleccione un vendedor",
      rules: [(value) => !!value || "Debe seleccionar un vendedor."],
    },
    select_version: {
      label: "Versión*",
      selected: null,
      placeholder: "Seleccione versión",
      // rules: [(value) => !!value || "Debe seleccionar versión."],
    },
    patente: {
      label: "Patente*",
      value: "",
      placeholder: "Escriba la patente...",
      hint: "Formato: LLLLNN, LLNNNN.",
      rules: [
        (value) => !!value || "Debe especificar una patente.",
        (value) => validarFactura(value) || "Formato de patente incorrecto",
      ],
    },
    factura: {
      label: "N° Factura",
      placeholder: "Escriba el n° de factura...",
      value: "",
      rules: [
        (value) => !!value || "Debe especificar un n° de factura.",
        (value) => validarFactura(value) || "Formato de factura incorrecto",
      ],
    },

    // == Campos tipo camion ==
    valor_asegurado: {
      label: "Valor*",
      value: "",
      placeholder: "Escriba el valor asegurado...",
      hint: "Valor asegurado del camión.",
      rules: [
        (value) => !!value || "El campo no puede quedar vacío",
        (value) => validarNumerico(value),
      ],
    },
    select_plazo: {
      label: "Plazo de crédito*",
      selected: 12,
      answers: [12, 24, 36, 48, 60],
      placeholder: "Seleccione un plazo",
      rules: [(value) => !!value || "Debe seleccionar un plazo de crédito."],
    },
    acoplado: {
      label: "Acoplado*",
      selected: false,
      hint: "El camión tiene acoplado?",
    },
    // == ==
    precio_venta: "",
    peso_carga: "",
    marca: "",
    modelo: "",
  }),
  computed: {
    ...mapGetters("Users", ["getBrands", "getModels"]),
    ...mapGetters("Internationalization", [
      "comunas",
      "regiones",
      "provincias",
    ]),
    years() {
      return this.$store.getters.yearsList;
    },
    country() {
      return this.$store.getters.getCountry;
    },
    patentMask: () => patentMask,
    getHintVehicleUse() {
      let hintText =
        "Haga click para establecer el estado del vehiculo como nuevo o usado.";
      if (this.select_new_vehicle.disabled_true) {
        hintText = "El canal solo permite vehiculos usados";
      }
      if (this.select_new_vehicle.disabled_false) {
        hintText = "El canal solo permite vehiculos nuevos";
      }
      return hintText;
    },

    // showGpsModal() {
    //   return this.suma_asegurada > 50000 && this.havegps === "SI";
    // },
  },
  methods: {
    ...mapActions("Users", ["findModels", "findBrandsType"]),
    ...mapActions("Internationalization", ["getLocations"]),
    SelectProduct() {
      this.formType = 1;
      this.loadingBrand = true;
      this.findBrandsType(this.formType).finally(
        () => (this.loadingBrand = false)
      );
    },
    change() {
      this.loadingModels = true;
      this.findModels({
        tipo_vehiculo: this.formType,
        marca: this.select_brand.selected.marca,
      }).finally(() => (this.loadingModels = false));
    },

    fillCarUse() {
      this.carUseLoading = true;
      this.$store
        .dispatch("REQUEST_CHANNEL_VEHICLE_USE")
        .then((response) => {
          this.allowNewCar = response.habilita_vehiculo_nuevo;
          this.allowUsedCar = response.habilita_vehiculo_usado;
        })
        .finally(() => {
          this.carUseLoading = false;
        });
    },

    fillYears() {
      this.yearsLoading = true;
      this.$store
        .dispatch("REQUEST_QUOTATION_YEARS")
        .finally(() => (this.yearsLoading = false));
    },

    submit() {
      return new Promise((resolve) => {
        // Establecer la data para enviar
        const dataNewQuot = {
          producto: this.select_product_type.selected.idMateria,
          producto_descripcion:
            this.select_product_type.selected.descripcionMateria,
          nuevo: this.selectedCarState === "new" ? true : false,
          marca: this.select_brand.selected.marca,
          patente: this.patente.value,
          modelo: this.select_model.selected.modelo,
          anio: this.select_year.selected,
          uso_producto: this.select_product_use.selected.uso_producto,
          uso_producto_descripcion:
            this.select_product_use.selected.uso_producto_descripcion,
          uso_producto_codigo: this.select_product_use.selected.codigo,
          obj_marca: this.select_brand.selected,
          obj_modelo: this.select_model.selected,
          gps: this.havegps,
          ...this.dataClient,
          ...this.dataCategory,
        };
        // == Campos para tipo camion ==
        const codigoProducto = this.select_product_type.selected?.codigoMateria;
        if (codigoProducto === "camion" || codigoProducto === "remolque") {
          dataNewQuot.producto_codigo = codigoProducto;
          dataNewQuot.valor_asegurado = this.valor_asegurado.value;
          dataNewQuot.acoplado = this.acoplado.selected;
          dataNewQuot.plazo_credito = this.select_plazo.selected;
        }
        // == Campos para venta asistida ==
        if (this.assistedSelling && this.select_dealer.selected) {
          dataNewQuot.venta_terceros = {
            venta_asistida: this.assistedSelling,
            concesionario: this.select_dealer.selected
              ? this.select_dealer.selected.concesionario ||
                this.select_dealer.selected._id
              : "",
            concesionario_descripcion: this.select_dealer.selected
              ? this.select_dealer.selected.concesionario_descripcion
              : "",
            sucursal: this.select_branchs.selected
              ? this.select_branchs.selected.sucursal ||
                this.select_branchs.selected._id
              : "",
            sucursal_descripcion: this.select_branchs.selected
              ? this.select_branchs.selected.sucursal_descripcion
              : "",
            vendedor: this.select_sellers.selected
              ? this.select_sellers.selected.vendedor ||
                this.select_sellers.selected._id
              : "",
            vendedor_descripcion: this.select_sellers.selected
              ? this.select_sellers.selected.vendedor_descripcion
              : "",
          };
        } else {
          dataNewQuot.venta_terceros = {
            venta_asistida: false,
          };
        }

        if (this.suma_asegurada) {
          dataNewQuot.suma_asegurada = this.suma_asegurada;
        }
        // == Data de Verificacion ==
        const payload = {
          rut: this.dataClient.numero_documento || this.rut,
          patente: this.patente.value,
          data: dataNewQuot,
        };

        this.formData = payload;
        resolve(payload);
      });
    },
  },
  mounted() {
    if (this.country) {
      this.getLocations(this.country);
    }
    this.region = this.regiones.find(
      (region) => region._id === this.dataClient.region
    );
    this.provincia = this.provincias.find(
      (provincia) => provincia._id === this.dataClient.provincia
    );
    this.comuna =
      this.comunas.find((comuna) => comuna._id === this.dataClient.comuna) ||
      this.comunas.find((comuna) => comuna.codigo === this.dataClient.comuna);
  },
  watch: {
    suma_asegurada(newVal) {
      if (+newVal > 50000 && this.havegps === "NO") {
        this.openGpsModal = true;
      }
    },
    havegps(newVal) {
      if (newVal === "NO" && +this.suma_asegurada > 50000) {
        this.openGpsModal = true;
      }
    },
    documentType(val, oldVal) {
      if (val === "factura" || val === "tramite") {
        this.patente.value = "FACTURA";
      }
      if (oldVal === "factura" || oldVal === "tramite") {
        this.patente.value = "";
      }
    },
    async country(val) {
      if (val) {
        await this.getLocations(this.country);
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-btn) {
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}
.selected {
  border: 1px solid #0077c8;
  background: rgba(0, 119, 200, 0.12);
}

:deep(.v-input--radio-group--column
    .v-radio:not(:last-child):not(:only-child)) {
  margin-bottom: 0;
}

:deep(.v-input) {
  width: 100%;
}
.inputInner {
  height: 40px;
  display: flex;
  background-color: #f4f3f6;
  border-radius: 4px 0px 0px 4px;
  align-items: center;
  padding: 0 8px;
}

:deep(.customInput .v-input__prepend-inner) {
  margin-top: 0 !important;
}

:deep(.customInput .v-input__slot) {
  padding: 0 12px 0 0 !important;
}

:deep(.gps .v-input) {
  margin-top: 0 !important;
  padding-top: 8px;
}
</style>
