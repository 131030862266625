<template name="AlertComponent">
  <v-alert
    dense
    :type="variant"
    border="left"
    colored-border
    :class="alertClass">
    <div class="d-flex">
      <template slot="prepend">
        <div>
          <Icon :name="icon" size="20px" :class="iconClass" />
        </div>
      </template>
      <slot name="content"></slot>
    </div>
  </v-alert>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "AlertComponent",
  props: {
    variant: String,
  },
  components: { Icon },
  computed: {
    icon() {
      let icon = "";
      switch (this.variant) {
        case "warning":
          icon = "priority_high";
          break;
        case "error":
          icon = "warning";
          break;
        case "info":
          icon = "info";
          break;
        case "success":
          icon = "check_circle";
          break;
      }

      return icon;
    },
    iconClass() {
      let iconClass = "";
      switch (this.variant) {
        case "warning":
          iconClass = "warning-text mr-2";
          break;
        case "error":
          iconClass = "error-text mr-2";
          break;
        case "info":
          iconClass = "info-text mr-2";
          break;
        case "success":
          iconClass = "success-text mr-2";
          break;
      }

      return iconClass;
    },
    alertClass() {
      let alertClass = "";
      switch (this.variant) {
        case "warning":
          alertClass = "warning-alert";
          break;
        case "error":
          alertClass = "error-alert";
          break;
        case "info":
          alertClass = "info-alert";
          break;
        case "success":
          alertClass = "success-alert";
          break;
      }

      return alertClass;
    },
  },
  methods: {},
};
</script>
<style scoped>
.material-symbols-rounded {
  color: v-bind("color") !important;
  font-size: v-bind("size");
}

:deep(i.v-icon) {
  font-size: inherit !important;
}
</style>
